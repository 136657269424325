<template>
  <b-card
    no-body
  >
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Выписок<br>на странице</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            variant="primary"
            :to="{ name: 'apps-excerpt-add'}"
          >
            {{ t('Load excerpt') }}
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="t('Search') + '...'"
            />
            <v-select
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              item-text="text"
              item-value="value"
              class="excerpt-filter-select"
              :placeholder="t('Select Status')"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ t(label) }}
                </span>
              </template>
            </v-select>
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refExcerptListTable"
      :items="fetchExcerpts"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="Вы ещё не загрузили ни одного файла"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #head(excerptStatus)>
        <feather-icon
          icon="TrendingUpIcon"
          class="mx-auto"
        />
      </template>
      <template #head(client)>
        {{ t('Сlient') }}
      </template>
      <template #head(total)>
        {{ t('Total') }}
      </template>

      <!-- Column: Id -->
      <template #cell(id)="data">
        <b-link
          v-if="data.item.status == 'file-ok'"
          :to="{ name: 'apps-excerpt-preview', params: { id: data.item.id }}"
          class="font-weight-bold"
        >
          #{{ data.value }}
        </b-link>
        <span v-else>
          #{{ data.value }}
        </span>
        <br>
        <b-avatar
          :id="`excerpt-row-${data.item.id}`"
          size="16"
          :variant="`light-${resolveExcerptStatusVariantAndIcon(data.item.status).variant}`"
        >
          <feather-icon
            :icon="resolveExcerptStatusVariantAndIcon(data.item.status).icon"
          />
        </b-avatar>
        {{ t(data.item.status) }}<br>
        {{ t(data.item.statusMsg) }}
      </template>
      <template #cell(userFileName)="data">
        {{ data.item.userFileName }}
        <br v-if="data.item.status === 'file-ok'">
        <span v-if="data.item.status === 'file-ok'">
          <b-link target="_blank" :href="'/apps/excerpt/preview/' + data.item.id">
            Открыть
          </b-link>
          |
          <b-link @click.prevent="XMLSingleDownload(data.item.id, data.item.fileTypeId)">
            XML
          </b-link>
        </span>
      </template>
      <!-- Column: Excerpt Status -->
      <template #cell(status)="data">
        <b-avatar
          :id="`excerpt-row-${data.item.id}`"
          size="16"
          :variant="`light-${resolveExcerptStatusVariantAndIcon(data.item.status).variant}`"
        >
          <feather-icon
            :icon="resolveExcerptStatusVariantAndIcon(data.item.status).icon"
          />
        </b-avatar>
        {{ t(data.item.status) }}<br>
        {{ t(data.item.statusMsg) }}
      </template>

      <!-- Column: Client -->
      <template #cell(client)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="avatarText(data.item.client.name)"
              :variant="`light-${resolveClientAvatarVariant(data.item.excerptStatus)}`"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.client.name }}
          </span>
          <small class="text-muted">{{ data.item.client.companyEmail }}</small>
        </b-media>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(dateCreate)="data">
        <span class="text-nowrap">
          {{ new Date(data.value).toLocaleDateString() + " " + new Date(data.value).toLocaleTimeString() }}
        </span>
      </template>

      <!-- Column: Balance -->
      <template #cell(balance)="data">
        <template v-if="data.value === 0">
          <b-badge
            pill
            variant="light-success"
          >
            Paid
          </b-badge>
        </template>
        <template v-else>
          {{ data.value }}
        </template>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            v-show="data.item.status == 'file-ok'"
            :id="`excerpt-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'apps-excerpt-preview', params: { id: data.item.id }})"
          />
          <b-tooltip
            title="Просмотр файла"
            :target="`excerpt-row-${data.item.id}-preview-icon`"
          />
        </div>
      </template>
      <template #cell(btns)="data">
        <span>
          <b-link target="_blank" :href="'/apps/excerpt/preview/' + data.item.id">
            Открыть
          </b-link>
          |
          <b-link @click.prevent="XMLSingleDownload(data.item.id, data.item.fileTypeId)">
            XML
          </b-link>
        </span>
      </template>
      <template #cell(filetype)="data">
        <span :style="{ color: data.item.fileTypeId === 9 || data.item.fileTypeId === 10 ? 'green' : (data.item.fileTypeId === 13 ? 'orange' : 'unset') }">
          <span v-if="data.item.fileType === '109'">КПТ</span>
          <span v-else>{{ data.item.fileType }}</span>
          {{ data.item.fileTypeId ? "| " + data.item.fileTypeId : "" }}
        </span>
      </template>
      <template #cell(kad)="data">
        {{ data.item.kad }}
        <br>
        <span :style="{ color: data.item.fileTypeId === 9 || data.item.fileTypeId === 10 ? 'green' : (data.item.fileTypeId === 13 ? 'orange' : 'unset') }">
          <span v-if="data.item.fileType === '109'">КПТ</span>
          <span v-else>{{ data.item.fileType }}</span>
          {{ data.item.fileTypeId ? "| " + data.item.fileTypeId : "" }}
        </span>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalExcerpts"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BPagination, BTooltip,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useExcerptsList from './useExcerptList'
import excerptStoreModule from '../excerptStoreModule'
import useDownloads from '../useDownloads'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BTooltip,

    vSelect,
  },
  setup() {
    const { t } = useI18nUtils()
    const EXCERPT_APP_STORE_MODULE_NAME = 'app-excerpt'
    // Register module
    if (!store.hasModule(EXCERPT_APP_STORE_MODULE_NAME)) store.registerModule(EXCERPT_APP_STORE_MODULE_NAME, excerptStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EXCERPT_APP_STORE_MODULE_NAME)) store.unregisterModule(EXCERPT_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      { value: 'file-new', label: t('file-new-select') },
      { value: 'file-failed', label: t('file-failed-select') },
      { value: 'file-ok', label: t('file-ok-select') },
      { value: 'file-failed-unzip', label: t('file-failed-unzip-select') },
      { value: 'file-is-not-supported', label: t('file-is-not-supported-select') },
      { value: 'file-notunique', label: t('file-notunique-select') },
      { value: 'file-cert-bad', label: t('file-cert-bad-select') },
      { value: 'file-underror', label: t('file-underror-select') },
      { value: 'file-sig-error', label: t('file-sig-error-select') },
    ]

    const {
      fetchExcerpts,
      tableColumns,
      perPage,
      currentPage,
      totalExcerpts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refExcerptListTable,
      statusFilter,

      refetchData,

      resolveExcerptStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useExcerptsList()

    const {
      XMLSingleDownload,
    } = useDownloads()

    return {
      fetchExcerpts,
      tableColumns,
      perPage,
      currentPage,
      totalExcerpts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refExcerptListTable,

      statusFilter,

      refetchData,

      statusOptions,

      avatarText,
      resolveExcerptStatusVariantAndIcon,
      resolveClientAvatarVariant,
      t,
      XMLSingleDownload,
    }
  },
}
</script>

<style lang="scss">
.w25{
  width: 25px !important;
}
.w50{
  width: 50px !important;
}
.per-page-selector {
  width: 90px;
}

.excerpt-filter-select {
  min-width: 244px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
