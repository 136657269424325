/* eslint-disable object-curly-newline */
import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default function useExcerptsList() {
  const { t } = useI18nUtils()

  // Use toast
  const toast = useToast()

  const refExcerptListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '# / Статус', sortable: true },
    // { key: 'status', label: t('status'), sortable: true },
    // { key: 'btns', label: '', sortable: false },
    { key: 'userFileName', label: t('userFileName'), sortable: true },
    { key: 'kad', label: t('kad'), sortable: true },
    // { key: 'filetype', label: t('filetype'), sortable: false },
    { key: 'dateCreate', label: t('dateCreate'), sortable: true },
  ]
  const perPage = ref(10)
  const totalExcerpts = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('dateCreate')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refExcerptListTable.value ? refExcerptListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalExcerpts.value,
    }
  })

  const refetchData = () => {
    refExcerptListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchExcerpts = (ctx, callback) => {
    store
      .dispatch('app-excerpt/fetchExcerpts', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then(response => {
        // console.log(response.data.Data)
        const { excerpts, total } = response.data.Data

        callback(excerpts)
        totalExcerpts.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching excerpts' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveExcerptStatusVariantAndIcon = status => {
    if (status === 'Partial Payment') return { variant: 'warning', icon: 'PieChartIcon' }
    if (status === 'Paid') return { variant: 'success', icon: 'CheckCircleIcon' }
    if (status === 'Downloaded') return { variant: 'info', icon: 'ArrowDownCircleIcon' }
    if (status === 'file-new') return { variant: 'warning', icon: 'InfoIcon' }
    if (status === 'file-ok') return { variant: 'success', icon: 'InfoIcon' }
    if (status === 'file-is-not-supported') return { variant: 'danger', icon: 'InfoIcon' }
    if (status === 'file-failed-unzip') return { variant: 'danger', icon: 'InfoIcon' }
    if (status === 'file-notunique') return { variant: 'danger', icon: 'InfoIcon' }
    if (status === 'file-sig-error') return { variant: 'danger', icon: 'InfoIcon' }
    if (status === 'file-cert-bad') return { variant: 'danger', icon: 'InfoIcon' }
    if (status === 'file-failed') return { variant: 'danger', icon: 'InfoIcon' }
    if (status === 'Draft') return { variant: 'primary', icon: 'SaveIcon' }
    if (status === 'Sent') return { variant: 'secondary', icon: 'SendIcon' }
    if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }

  const resolveClientAvatarVariant = status => {
    if (status === 'Partial Payment') return 'primary'
    if (status === 'Paid') return 'danger'
    if (status === 'Downloaded') return 'secondary'
    if (status === 'file-new') return 'warning'
    if (status === 'file-ok') return 'success'
    if (status === 'file-failed') return 'danger'
    if (status === 'Draft') return 'warning'
    if (status === 'Sent') return 'info'
    if (status === 'Past Due') return 'success'
    return 'primary'
  }

  return {
    fetchExcerpts,
    tableColumns,
    perPage,
    currentPage,
    totalExcerpts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refExcerptListTable,

    statusFilter,

    resolveExcerptStatusVariantAndIcon,
    resolveClientAvatarVariant,

    refetchData,
  }
}
